import React, { Component } from 'react';

import { Container } from 'constate';

import { ProfileForm } from '../../components/ProfileForm';
import { profileEffects } from '../../app/effects/profile';

import Layout from '../../components/layout';

const BasicProfilePage = () => (
  <Layout>
    <Container
      context='primary'
      effects={profileEffects}
      >
      {({saveProfile}) => (
        <div className='row mb5 pt3'>
          <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
          <div className="col-xs-10 col-sm-10 col-md-2 col-lg-6">
            <h1>Create Account</h1>
            <h3>Step 3: Enter profile details</h3>
            <ProfileForm onSubmit={saveProfile} />
          </div>
        </div>
      )}
    </Container>
  </Layout>
)

export default BasicProfilePage;