import { handleResponse } from './utils';

const serverURL = process.env.GATSBY_SWSM_ENDPOINT;

export const saveProfile = (owner, organization, ephemeralToken) => {

  const payload = {
    owner,
    organization,
  };

  const request = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer: ' + ephemeralToken
    }
  }

  return fetch(`${serverURL}/profile/complete/organization`, request)
  .then(handleResponse)
  .catch((error) => ({ error }));
}