import { navigate } from '@reach/router';

import { saveProfile } from '../api/profile';
import { onError } from './errors';

export const profileEffects = {
  saveProfile: ({name, organization}) => async ({state, setState}) => {
    const { ephemeralToken } = state;
    
    // TODO Be more defensive about whether ephemeral token exists

    const { accessToken, user, accounts, error } = await saveProfile(name, organization, ephemeralToken);
    if (error) {
      const { auth } = state;
      onError(error, auth);
    } else if (accessToken) {
      setState(state => ({ accessToken, user, accounts }));
      navigate('/app/dashboard');
    }
  },
};