import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link }  from 'gatsby';

import * as Yup from 'yup';

const ProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  organization: Yup.string()
    .required('Required'),
});

const initialValues = {
  name: '',
  organization: '',
};

export const ProfileForm = ({onSubmit}) => {
  const s = (false) ? 'bg-yellow' : 'bg-moon-gray';
  return (
  <Formik
    initialValues={initialValues}
    validationSchema={ProfileSchema} 
    onSubmit={onSubmit}>
    {({isSubmitting, isValid}) => {
      const s = (isValid) ? 'bg-yellow' : 'bg-moon-gray';
      return (
        <Form>
          <div>
            <label htmlFor="name">Your Name</label>
          </div>
          <div className='mt2'>
            <Field className='ba w5' name="name" type="text"/>
            <ErrorMessage name="name" component="div" />
          </div>
          <div className='mt3'>
            <label htmlFor="name">Company Name</label>
          </div>
          <div className='mt2'>
            <Field className='ba w5' name="organization" /> 
            <ErrorMessage name="organization" component="div" />
          </div>
          <div className='f7 mw5 mt4'>
            By clicking on 'Create new account' below, you are agreeing to
            the <Link className='link underline blue' to={'/tos'}>Terms Of Service</Link> and
            the <Link className='link underline blue' to={'/privacy'}>Privacy Policy</Link>.
          </div>

          <div className='mt3'>
            <button className={s} type="submit" disabled={!isValid || isSubmitting}>Create new account</button>
          </div>
        </Form>
      )}}
  </Formik>
)};
